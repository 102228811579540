<template>
    <v-container fluid>
  
      <v-row>        
        <v-col
          cols="12"
        >
          <v-card
            dark
            outlined
          >
            <v-card-title class="secondary py-1 text-subtitle-1">
              <v-icon left dense> mdi-home </v-icon>
              Categories
              <!-- <v-spacer/> -->
              <!-- <v-btn
                x-small
                dark
                @click="haddleClick()"
              >
                <v-icon left x-small>
                  mdi-plus
                </v-icon>
                Add User
              </v-btn> -->
            </v-card-title>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="headers"
                :items="productCategories"
                single-select
                class="elevation-5"
              >
              <template v-slot:item.CategoryName="{ item }">
                  {{ item.CategoryName ? item.CategoryName : 'No data available' }}
              </template>
              
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                <v-row>
                    <v-col cols="2">
                    <v-autocomplete
                        :items="allStallHolders"
                        v-model="selectedStallHolder"
                        item-text="StallholderName"
                        return-object
                        filled
                        dense
                        outlined
                        hide-details
                        label="Stallholders"
                        @change="haddleChangeSH"
                    >
                        <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-title>
                            <v-icon small color="red lighten-1">
                                mdi-information
                            </v-icon>
                            No event found
                            </v-list-item-title>
                        </v-list-item>
                        </template>
                    </v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                    <!-- <v-autocomplete
                        :items="stalls"
                        v-model="selectedStall"
                        item-text="StallName"
                        return-object
                        filled
                        dense
                        outlined
                        hide-details
                        label="Stalls"
                    >
                        <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-title>
                            <v-icon small color="red lighten-1">
                                mdi-information
                            </v-icon>
                            No event found
                            </v-list-item-title>
                        </v-list-item>
                        </template>
                    </v-autocomplete> -->
                    </v-col>
                </v-row>
                  <v-dialog
                    v-model="dialog"
                    max-width="800px"
                    dark
                  >
                  
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="selectedStallHolder.length === 0"
                      >
                        <v-icon left x-small>
                          mdi-plus
                        </v-icon>
                        Add Categories
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h6">
                        {{ formTitle }}
                      </v-card-title>
                      <v-divider></v-divider>
  
                      <v-card-text>
                        <v-container>
                         <v-form 
                            ref="form"
                            v-model="valid"
                            lazy-validation
                          >

                            <v-row>
                                <v-col cols="6">
                                    <label><B>Event :-</B> {{ selectedEvent.EventName ? selectedEvent.EventName : 'N/A'}}</label>
                                </v-col>
                                <v-col cols="6">
                                    <label><B>Stallholders :-</B> {{ selectedStallHolder.StallholderName ? selectedStallHolder.StallholderName : 'N/A'}}</label>
                                </v-col>
                            </v-row>

                            <v-text-field
                            v-model="editedItem.CategoryName"
                            label="Category Name"
                            :rules="[v => !!v || 'Category Name is required']"
                            ></v-text-field>

                         </v-form>
                        </v-container>
                      </v-card-text>
  
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog dark v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to delete Category ?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                >
                  No categories for this event
                </v-btn>
              </template>
              </v-data-table>
  
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import 'izitoast/dist/css/iziToast.min.css'; // loading css 
  import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now
  import {
    mapState,
    mapActions,
  } from 'vuex'
  
  export default {
    data: () => ({
      selectedStallHolder: '',
      dialog: false,
      dialogDelete: false,
      
      selectedStall: '',
      stalls : [],
      headers: [
        { text: "Category Name", value: "CategoryName"},
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      productCategories: [],
      selectedAllCategories: [],
      stallHolders: [],
      stallHoldersList: [],
      editedIndex: -1,
      editedItem: {
        EventId: '',
        StallholderId: '',
        CategoryName: ''
      },
      defaultItem: {
        EventId: '',
        StallholderId: '',
        CategoryName: ''
      },
      allFetchedStallholders: [],
      valid: true
    }),

  
    computed: {
      ...mapState('filter', ['globalEventFilter',
      // 'allEvents',
      // 'allStallHolders'
    ]),

    allStallHolders: {
        get() {
          return this.allFetchedStallholders;
        },
        set (val) {
          this.allFetchedStallholders = val
        }
      },

      formTitle () {
        return this.editedIndex === -1 ? 'New Category' : 'Edit Category'
      },

      selectedEvent: {
        get() {
          return this.globalEventFilter
        },
        set (value) {
          console.log("EVENT IS CHANGED, MAKE API REQUEST", value)
        }
      },

      // events() {
      //   return this.allEvents.map((obj) => {
      //     return {text: obj.EventName, value: obj.EventId}
      //   });
      // }
    },
  created() {
      this.halldeStallHolder()
      this.getAllCategories()
      this.setAllStallholders()
    },
    watch: {
          dialog (val) {
            val || this.close()
          },
          dialogDelete (val) {
            val || this.closeDelete()
          },
          'globalEventFilter.EventId'(newVal) {
            if (newVal) {
              this.fetchStallHolders();
            }
          }
    },
    methods: {
      ...mapActions('admin', ['getProducts', 'createCategories',]),

      setAllStallholders: function () {
            try {
                this.allFetchedStallholders = this.globalEventFilter.Stallholders
            } catch (e) {
              console.log("ERROR: ", e)
            }
        },

      getAllCategories() {        
        // let eventId = localStorage.getItem('setSelectedEventId')
        let StallholderId = localStorage.getItem('setSelectedStallholderId')

        let paramObj = {}
            paramObj.EventId = this.globalEventFilter.EventId;
            paramObj.StallholderId = StallholderId;

        this.getProducts(paramObj).then(response => {
          this.selectedAllCategories = response.ProductCategories;
          this.productCategories = response.ProductCategories;
        })
      },

      haddleChangeSH: function (obj) {

        this.productCategories = this.selectedAllCategories.filter(element => {
          return element.StallholderId === obj.StallholderId;
        });

        // localStorage.setItem('setSelectedEventId', this.selectedEvent.EventId);
        localStorage.setItem('setSelectedStallholderId', obj.StallholderId);
        this.getAllCategories();
      },

      halldeStallHolder: function() {
        if (this.selectedEvent.EventName !== undefined) {
          this.haddleChangeEvent(this.selectedEvent);
        }
      },
      
      fetchStallHolders() {
        this.allStallHolders =  this.globalEventFilter.Stallholders
        // localStorage.setItem('setSelectedEventId', this.globalEventFilter.EventId);
        localStorage.setItem('setSelectedStallholderId', this.globalEventFilter.Stallholders[0].StallholderId);
        this.getAllCategories()
      },

      // haddleChangeEvent: function(obj) {
      haddleChangeEvent: function() {
        this.stallHolders = [];
        this.editedItem.StallholderId = '';
        // let selectedObj = this.allEvents.filter(element => {
        //   return element.EventId === obj.EventId;
        // });
        // this.stallHoldersList = selectedObj[0].Stallholders;
        
        // this.stallHolders = selectedObj[0].Stallholders.map((obj) => {
        this.stallHolders = this.globalEventFilter.Stallholders.map((obj) => {
          return {text: obj.StallholderName, value: obj.StallholderId}
        });

      },

      editItem (item) {
        this.editedIndex = this.productCategories.indexOf(item);
        
        this.editedItem.EventId = item.EventId;
        this.editedItem.StallholderId = item.StallholderId;
        this.editedItem.CategoryId = item.CategoryId;
        this.editedItem.CategoryName = item.CategoryName;

        let selected = this.allStallHolders.filter(element => {
          return element.StallholderId === item.StallholderId;
        });

        this.selectedStallHolder = selected[0];

        this.dialog = true
      },
  
      deleteItem (item) {
        this.editedIndex = this.allStallHolders.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
  
      deleteItemConfirm () {
        this.allStallHolders.splice(this.editedIndex, 1)
        
        try {
          if (this.editedItem.StallholderId) {
              let event_item = [
                  {
                      Type: "product_categories",
                      Action: "delete",
                      Properties: this.editedItem
                  }
              ]
              
              let submitEvent = {
                  event_items: event_item
              }
              this.createCategories(submitEvent).then(response => {
                
                console.log("deleteItemConfirm () createCategories response: ", response)

                  iziToast.success({
                      title: 'Category',
                      message: 'Successfully delete record!',
                      position: 'topRight'
                  });

                  this.getAllCategories();

              }).catch(err => {
                  console.log("ERROR: ", err)

                  iziToast.warning({
                      title: 'Category',
                      message: 'Fail to delete record!',
                      position: 'topRight'
                  });
              })
              
              this.setAllStallholders();
          }
        } catch (e) {
          console.log("ERROR: ", e)
        }

        this.closeDelete()
      },
  
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      save () {
        if (this.$refs.form.validate()) {

          try {
            if (this.$refs.form.validate()) {
                this.editedItem.EventId = this.selectedEvent.EventId;
                this.editedItem.StallholderId = this.selectedStallHolder.StallholderId;
                let event_item = [
                    {
                        Type: "product_categories",
                        Action: this.editedIndex === -1 ? 'Create' : 'Edit',
                        Properties: this.editedItem
                    }
                ]
                
                let submitEvent = {
                    event_items: event_item
                }
                this.createCategories(submitEvent).then(response => {
                  
                    console.log("save () createCategories response: ", response)

                    iziToast.success({
                        title: 'Category',
                        message: 'Successfully inserted record!',
                        position: 'topRight'
                    });

                    this.getAllCategories();

                }).catch(err => {
                  console.log("ERROR: ", err)

                    iziToast.warning({
                        title: 'Category',
                        message: 'Fail to inserted record!',
                        position: 'topRight'
                    });
                })
            }
          } catch (e) {
            console.log("ERROR: ", e)
          }
          this.close()
        }
      }
    }
  }
  </script>
  <style scoped>
  h1{
    color: #a09c94;
    font-family: sans-serif;
  }
  </style>